<template>
  <div class="card">

    <div class="bg-blue-light p-1">
      <TitleButton
        :showBtn="true"
        :showAddNew="false"
        title="Create Pharmacy Bill"
        @onClickCloseButton="goToList"
      />
    </div>

    <div class="my-2 px-2">
      <TableHeaderData
        :leftSideData="leftSideData"
        :rightSideData="rightSideData"
      />
    </div>

    <div class="col-12 px-2">
      <ListTable
        :tableItems="tableItems"
        :discount-account-heads="discountAccountHeads"
        @addNewItem="addNewItem"
        @handleSubmit="handleSubmit"
        :submitLoading="submitLoading"
      />
    </div>
    <GlobalLoader />
  </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import ListTable from '@/components/molecule/company/hospital/investigation/CreateIpdBillingTable.vue';
import TableHeaderData from '@/components/atom/TableHeaderData.vue';
import {onMounted, ref, inject, provide} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import handleHospitalBilling from "@/services/modules/hospital/billing";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import {useStore} from "vuex";
import useDate from "@/services/utils/day";
import handlePurchase from "@/services/modules/purchase";
import handleCompany from "@/services/modules/company";
import handleBusinessesLocations from "@/services/modules/businessesLocations";
import hospitalPdfPrinterHelper from "@/services/utils/hospitalPdfPrinterHelper";

const $route = useRoute();
const $router = useRouter();
const $store = useStore();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const {fetchDoctorMedicineOrder} = handleHospitalBilling();
const { fetchSalesBill, ...rest } = handlePurchase();
const { fetchCompanyInfo } = handleCompany();
const {fetchBusinessLocationsList} = handleBusinessesLocations()
const { generatePdf } = hospitalPdfPrinterHelper();
const {currentDate} = useDate();

const itemSkeleton = {
    name: '',
    description: '',
    quantity: null,
    rate: 0,
    discount_percent: 0,
    vat: 0,
    sub_total: 0,
    discount_amount: 0,
    vat_amount: 0,
    total: 0,
    amount: 0,
    total_amount: 0,
    isEdit: false,
};

const company = ref({});
const locations = ref([]);
const tableItems = ref([]);
const discountAccountHeads = ref([]);
const submitLoading = ref(false);
const invoiceRes = ref(null);

const companyId = $route.params.companyId;

const formData = ref({
    company_id: companyId,
    contact_profile_id: null,
    account_head_id: null,
    ipd_register_id: null,
    vat_payable_account_head_id: null,
    project_id: null,
    cost_centre_id: null,
    location_id: null,
    mop_account_head_id: null,
    discount_head_id: null,
    service_resource_id: null,
    receipt_reference: null,
    adjustment_amount: null,
    receipt_description: "Pharmacy bill payment",
    payment: 0,
    status: "active",
    date: currentDate(),
    has_item_detail: true,
    description: "",
    item_details: [],
    sale_type: "pharmacy_invoice",
    receipt_type: "pharmacy_invoice"
})

provide('formData', formData);

const leftSideData = ref([
  {
    key: 'Patient ID',
    value: ''
  },
  {
    key: 'Patient Name',
    value: ''
  },
  {
    key: 'Gender & Age',
    value: ''
  },
  {
    key: 'Patient Phone No',
    value: ''
  },
  {
    key: 'Patient Address',
    value: ``
  }
])

const rightSideData = ref([
  {
    key: 'Invoice Date',
    value: ''
  },
  {
    key: 'Admission No',
    value: ''
  },
  {
    key: 'Word',
    value: ''
  },
  {
    key: 'Consultant',
    value: ''
  }
])

const addNewItem = () => {
  tableItems.value.push({ ...itemSkeleton });
}

const goToList = () => {
  $router.push({
    name: 'pharmacy-billing',
    params: {
      companyId: $route.params.companyId,
      moduleId: $route.params.moduleId,
      menuId: $route.params.menuId,
      pageId: $route.params.pageId
    },
    query: $route.query

  })
}

const setTableHeaderData = (data) => {
  leftSideData.value = [
    {
      key: 'Patient ID',
      value: data.patient_id
    },
    {
      key: 'Patient Name',
      value: data.patient_name
    },
    {
      key: 'Gender & Age',
      value: `${data.gender} - ${data.age} Years`
    },
    {
      key: 'Patient Phone No',
      value: data.mobile
    },
    {
      key: 'Patient Address',
      value: data.full_address ?? "N/A"
    }
  ]

  rightSideData.value = [
    {
      key: 'Invoice Date',
      value: currentDate()
    },
    {
      key: 'Admission No',
      value: data.admission_no
    },
    {
      key: 'Word',
      value: data.word_no
    },
    {
      key: 'Consultant',
      value: data.prescription_type === 'opd_prescription' ? data.opd_consultant : data.ipd_consultant
    }
  ]
}

const setFormData = (data) => {
    formData.value.contact_profile_id = data.contact_profile_id;
    formData.value.ipd_register_id = data.ipd_register_id;
    formData.value.service_resource_id = data.prescription_type === 'opd_prescription' ?
                                            data.service_resource_id : data.human_resource_id;
}

const handleSubmit = () => {
    const emptyQtyItem = tableItems.value.filter((item) => !item.quantity);
    if (emptyQtyItem && emptyQtyItem.length > 0) {
        showError("Item quantity cannot be empty");
        return;
    }

    submitLoading.value = true;
    if (!formData.value.date) formData.value.date = currentDate();
    formData.value.item_details = tableItems.value;
    let copyFormData = JSON.parse(JSON.stringify(formData.value))
    copyFormData.item_details = formData.value.item_details
    copyFormData.doctor_order_id = $route.params.orderId
    copyFormData.sale_type = "pharmacy_invoice"
    copyFormData.receipt_type = "pharmacy_invoice"

    rest.storeBillPayment(copyFormData)
        .then(res => {
            if (res.status) {
                showSuccess(res.message)
                goToPrint(res.data.id)
                goToList();
                return;
            }
            showError(res.message)
        })
        .catch(err => {
            showError(err.response.data.message)
        })
}

const goToPrint = (id) => {
    const companyQuery = `?company_id=${companyId}`;

    fetchSalesBill(id, companyQuery).then( (res) =>{
        if( res ){
            invoiceRes.value = res.data;
        }
    }).then( () => {
        generatePdf(company.value, invoiceRes.value, 'BILL', true, true)
    }).catch( (err) => {
        console.log(err);
    })
}

onMounted(() => {
    const companyQuery = `?company_id=${companyId}`;
    const locationQuery = `${companyQuery}&location_type=fg_warehouse`;
    const doctorOrderId = $route.params.orderId;

    $store.commit('setLoading', true);

    Promise.all([
        fetchDoctorMedicineOrder(doctorOrderId, companyQuery).then(res => {
            if (res.status && res.data && res.data.medicine_orders && Array.isArray(res.data.medicine_orders)) {
                setTableHeaderData(res.data);
                setFormData(res.data);
                tableItems.value = res.data.medicine_orders.map(order => {
                    return {
                        ...itemSkeleton,
                        product_id: order.product_id,
                        name: order.product.name,
                        rate: order.product.selling_rate ?? 0,
                        description: order.product.description ?? null
                    }
                })
                return;
            }

            showError(res.message)

        }),
        rest.fetchAccountReceivable(companyQuery).then(res => {
            if (res.data) formData.value.account_head_id = res.data[0].id;
        }),
        rest.fetchVatPayable(companyQuery).then(res => {
            if (res.data) formData.value.vat_payable_account_head_id = res.data[0].id;
        }),
        fetchCompanyInfo(companyId).then(res => {
            if (res.data) company.value = res.data;
        }),
        rest.getAccountHeadBySlag("discount_account", companyQuery).then(res => {
            if (res.data) {
                discountAccountHeads.value = res.data;
                formData.value.discount_head_id = res.data.length ? res.data[0].id : null;
            }
        }),
        fetchBusinessLocationsList(locationQuery).then(res=> {
            if(res.data) {
                locations.value = res.data
                formData.value.location_id = res.data[0].id
            }
        })
    ]).finally(() => $store.commit('setLoading', false))
})

</script>

<style scoped>
address {
  margin-bottom: 0 !important;
}
</style>